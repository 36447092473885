<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row">
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES_PAYMENTS.ATTENTION") }}</dt>
          <dd class="col-sm-8">
            <div v-html="propertiesPayment.attention"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES_PAYMENTS.AMOUNT") }}</dt>
          <dd class="col-sm-8">
            <div>{{ $formatCurrency(propertiesPayment.amount) }}</div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES_PAYMENTS.SOURCE") }}</dt>
          <dd class="col-sm-8">
            {{
              $t(
                `PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${propertiesPayment.source}`
              )
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="propertiesPayment.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("PROPERTIES_PAYMENTS.DATE") }}</dt>
          <dd class="col-sm-8" v-if="propertiesPayment.date">
            {{ $formatDate(propertiesPayment.date, "dddd D MMMM YYYY HH:mm") }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="propertiesPayment.organization" />
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="propertiesPayment.created_at">
            {{ $formatDate(propertiesPayment.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="propertiesPayment.updated_at">
            {{ $formatDate(propertiesPayment.updated_at) }}
          </dd>
        </dl>
      </div>
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-8"></dt>
          <dd class="col-sm-4 text-right">
            <base-button type="button" @click="print" class="elite-button add">
              <span v-if="loading" class="btn-inner--icon">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span v-else class="btn-inner--icon">
                <i class="far fa-print"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("PROPERTIES.PRINT_RECEIPT") }}
              </span>
            </base-button>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { PROPERTIES_PAYMENTS_SOURCES } from "@/constants/propertiesPayment";
import swal from "sweetalert2";
export default {
  name: "properties-payment-view-global",

  components: {},

  props: ["propertiesPayment"],

  data() {
    return {
      PROPERTIES_PAYMENTS_SOURCES,
      loading: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    async print() {
      this.loading = true;
      swal.fire({
        title: this.$t("COMMON.LOADING"),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "propertiesPayments/print",
          this.propertiesPayment.id
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
        this.loading = false;
      } catch (error) {
        if (error.response) {
          if (error?.response?.status === 404) {
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
            });
          } else {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
        }
        swal.close();
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    propertiesPayment(propertiesPayment) {},
  },
};
</script>
