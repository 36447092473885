<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!propertiesPayment">
      <span class="loader"></span>
    </span>
    <div v-if="propertiesPayment" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <properties-payment-view-global
            :propertiesPayment="propertiesPayment"
          />
        </tab-pane>

        <tab-pane title="propertiesPayment" id="3">
          <span slot="title">
            <i class="fab fa-accusoft"></i>
            {{ $t("CONTACTS.PROPERTIES") }}
          </span>
          <properties-payment-property-list-table
            :filterPropertiesPayment="propertiesPayment.id"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import PropertiesPaymentViewGlobal from "../partials/PropertiesPaymentViewGlobal.vue";
import PropertiesPaymentPropertyListTable from "../partials/PropertiesPaymentPropertyListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PropertiesPaymentViewGlobal,
    PropertiesPaymentPropertyListTable,
  },

  mixins: [requestErrorMixin],

  props: {
    propertiesPaymentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      propertiesPayment: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "propertiesPayments/get",
          this.propertiesPaymentId
        );
        this.propertiesPayment =
          this.$store.getters["propertiesPayments/propertiesPayment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
