<template>
  <div class="form-group properties-selector">
    <div
      v-for="(item, key) in propertiesPaymentsModel"
      :key="key"
      class="properties-wrapper"
    >
      <div class="form-group-item type">
        <label class="form-control-label">
          {{ $t("PROPERTIES_PAYMENTS.PROPERTY") }}
        </label>
        <property-selector
          :property="item.property.id?.toString()"
          :filterOrganization="filterOrganization"
          v-if="
            !filterProperty &&
            $currentUserCan($permissions.PERM_VIEW_ANY_PROPERTIES)
          "
          @propertyChanged="
            (propertyId) => {
              item.property.id = propertyId;
            }
          "
        />
      </div>
      <div class="form-group-item name">
        <base-input
          type="number"
          step="0.01"
          :label="$t('PROPERTIES_PAYMENTS.AMOUNT')"
          :placeholder="$t('PROPERTIES_PAYMENTS.AMOUNT')"
          v-model="item.amount"
          @change="propertiesPaymentsChanged"
        />
      </div>
      <div class="remove-properties-button">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn btn-danger"
          @click.prevent="() => removePropertyPayment(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button
      type="button"
      class="btn add"
      @click.prevent="addPropertyPayment"
    >
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import PropertySelector from "@/components/PropertySelector.vue";

export default {
  name: "properties-amounts-inputs",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    PropertySelector,
  },

  props: {
    propertiesPaymentsData: {
      type: Array,
      required: true,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
    filterProperty: {
      type: String,
      default: null,
      description: "property id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      propertiesPaymentsModel: cloneDeep(this.propertiesPaymentsData),
    };
  },

  setup() {},

  created() {},

  methods: {
    addPropertyPayment() {
      this.propertiesPaymentsModel.push({
        property: { type: "properties", id: null },
        amount: 0,
        type: "properties-payments-properties",
      });
      this.propertiesPaymentsChanged();
    },

    removePropertyPayment(key) {
      this.propertiesPaymentsModel = this.propertiesPaymentsModel.filter(
        (_, idx) => idx !== key
      );
      this.propertiesPaymentsChanged();
    },

    propertiesPaymentsChanged() {
      const propertiesPaymentsModelData = cloneDeep(
        this.propertiesPaymentsModel
      ).map((item) => {
        return { ...item, amount: parseFloat(item.amount) };
      });
      this.$emit("propertiesPaymentsChanged", propertiesPaymentsModelData);
    },
  },

  watch: {
    propertiesPaymentsData(propertiesPaymentsData) {
      this.propertiesPaymentsModel = cloneDeep(this.propertiesPaymentsData);
    },
  },
};
</script>

<style lang="scss">
.form-group {
  &.properties-selector {
    > label {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .form-group-item {
      flex: 1;
      padding: 0 10px;
      .el-select {
        margin-top: 8px;
      }
      &.remove {
        max-width: 53px;
      }
    }

    .remove-properties-button {
      padding-left: 10px;
      button {
        box-shadow: none;
        border: none;
        margin: 0;
        &.btn-danger {
          width: 43px;
          height: 43px;
          border-radius: 8px;
          background-color: #f5365c;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }

    button {
      &.add {
        width: 43px;
        height: 43px;
        border-radius: 8px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }

  .properties-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 0 0 0;

    .form-group-item {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
