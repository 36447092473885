<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.ATTENTION')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.ATTENTION')"
        v-model="propertiesPayment.attention"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.attention" />
    </div>
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <!-- Organization -->
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="propertiesPayment.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId, organization) => {
              propertiesPayment.organization.id = organizationId;
              onFormChanged();
            }
          "
          :disabled="!!propertiesPayment.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.AMOUNT')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.AMOUNT')"
        v-model="propertiesPayment.amount"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.amount" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.SOURCE')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
          v-model="propertiesPayment.source"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in PROPERTIES_PAYMENTS_SOURCES"
            :key="key"
            :label="$t(`PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.source" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.DATE')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.DATE')"
        v-model="propertiesPayment.date"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('PROPERTIES_PAYMENTS.DATE')"
          :config="{
            allowInput: true,
            enableTime: false,
            type: 'date',
            maxDate: 'today',
            locale: $flatPickrLocale(),
            disable: [
              function (date) {
                return date.getDay() === 0;
              },
            ],
          }"
          class="form-control datepicker"
          v-model="propertiesPayment.date"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.date" />
    </div>
    <hr />
    <div class="form-wrapper full">
      <h3>{{ $t("COMMON.PROPERTIES") }}</h3>
      <properties-amounts-inputs
        :label="$t('COMMON.PROPERTIES')"
        :filterOrganization="propertiesPayment.organization.id"
        :propertiesPaymentsData="propertiesPayment.propertiesPaymentsProperties"
        @propertiesPaymentsChanged="
          (propertiesPaymentsData) => {
            propertiesPayment.propertiesPaymentsProperties =
              propertiesPaymentsData;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.property" />
    </div>
    <div class="form-wrapper full">
      <base-input
        :label="$t('PROPERTIES_PAYMENTS.EXCERPT')"
        :placeholder="$t('PROPERTIES_PAYMENTS.EXCERPT')"
      >
        <html-editor
          v-model="propertiesPayment.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>
    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          propertiesPayment.id
            ? $t("PROPERTIES_PAYMENTS.EDIT_PROPERTIES_PAYMENT")
            : $t("PROPERTIES_PAYMENTS.ADD_PROPERTIES_PAYMENT")
        }}
      </base-button>
    </div>
  </form>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { PROPERTIES_PAYMENTS_SOURCES } from "@/constants/propertiesPayment";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PropertiesAmountsInputs from "@/components/PropertiesAmountsInputs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    PropertiesAmountsInputs,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["propertiesPaymentData", "formErrors", "loading"],

  data() {
    let propertiesPaymentData = cloneDeep(this.propertiesPaymentData);
    propertiesPaymentData = this.$fillUserOrganizationData(
      propertiesPaymentData
    );
    return {
      propertiesPayment: propertiesPaymentData,
      PROPERTIES_PAYMENTS_SOURCES,
    };
  },

  created() {},

  mounted() {},

  methods: {
    async handleSubmit() {
      let propertiesPaymentData = cloneDeep(this.propertiesPayment);
      propertiesPaymentData = this.$fillUserOrganizationData(
        propertiesPaymentData
      );

      propertiesPaymentData.propertiesPaymentsProperties =
        propertiesPaymentData.propertiesPaymentsProperties.filter((item) => {
          return !!item.property.id && !!item.amount;
        });
      propertiesPaymentData.amount = parseFloat(propertiesPaymentData.amount);

      if (propertiesPaymentData.propertiesPaymentsProperties.length == 0) {
        await swal.fire({
          title: this.$t("ERRORS.NO_PROPERTY_SELECTED"),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        return;
      }

      const totalProperiesAmount =
        propertiesPaymentData.propertiesPaymentsProperties.reduce(
          (sum, item) => sum + item.amount,
          0
        );
      if (totalProperiesAmount != propertiesPaymentData.amount) {
        await swal.fire({
          title: this.$t("ERRORS.PROPERTIES_AMOUNTS_PAYMENT"),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        return;
      }

      this.$emit("propertiesPaymentSubmitted", propertiesPaymentData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    propertiesPaymentData(propertiesPaymentData) {
      if (propertiesPaymentData) {
        this.propertiesPayment = {
          ...this.propertiesPayment,
          ...cloneDeep(propertiesPaymentData),
        };
      }
    },
  },
};
</script>
