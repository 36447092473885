var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.ATTENTION')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.ATTENTION')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertiesPayment.attention),callback:function ($$v) {_vm.$set(_vm.propertiesPayment, "attention", $$v)},expression:"propertiesPayment.attention"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attention}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.propertiesPayment.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.propertiesPayment.id},on:{"organizationChanged":(organizationId, organization) => {
            _vm.propertiesPayment.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.AMOUNT')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.AMOUNT')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertiesPayment.amount),callback:function ($$v) {_vm.$set(_vm.propertiesPayment, "amount", $$v)},expression:"propertiesPayment.amount"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.amount}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.SOURCE')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.SOURCE')}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"clearable":true,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.SOURCE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.propertiesPayment.source),callback:function ($$v) {_vm.$set(_vm.propertiesPayment, "source", $$v)},expression:"propertiesPayment.source"}},_vm._l((_vm.PROPERTIES_PAYMENTS_SOURCES),function(item,key){return _c('el-option',{key:key,staticClass:"select-primary",attrs:{"label":_vm.$t(`PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${item}`),"value":item}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.source}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES_PAYMENTS.DATE')} (*)`,"placeholder":_vm.$t('PROPERTIES_PAYMENTS.DATE')},on:{"change":_vm.onFormChanged},model:{value:(_vm.propertiesPayment.date),callback:function ($$v) {_vm.$set(_vm.propertiesPayment, "date", $$v)},expression:"propertiesPayment.date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('PROPERTIES_PAYMENTS.DATE'),"config":{
          allowInput: true,
          enableTime: false,
          type: 'date',
          maxDate: 'today',
          locale: _vm.$flatPickrLocale(),
          disable: [
            function (date) {
              return date.getDay() === 0;
            },
          ],
        }},on:{"on-change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertiesPayment.date),callback:function ($$v) {_vm.$set(_vm.propertiesPayment, "date", $$v)},expression:"propertiesPayment.date"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.date}})],1),_c('hr'),_c('div',{staticClass:"form-wrapper full"},[_c('h3',[_vm._v(_vm._s(_vm.$t("COMMON.PROPERTIES")))]),_c('properties-amounts-inputs',{attrs:{"label":_vm.$t('COMMON.PROPERTIES'),"filterOrganization":_vm.propertiesPayment.organization.id,"propertiesPaymentsData":_vm.propertiesPayment.propertiesPaymentsProperties},on:{"propertiesPaymentsChanged":(propertiesPaymentsData) => {
          _vm.propertiesPayment.propertiesPaymentsProperties =
            propertiesPaymentsData;
          _vm.onFormChanged();
        }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.property}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('PROPERTIES_PAYMENTS.EXCERPT'),"placeholder":_vm.$t('PROPERTIES_PAYMENTS.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.propertiesPayment.excerpt),callback:function ($$v) {_vm.$set(_vm.propertiesPayment, "excerpt", $$v)},expression:"propertiesPayment.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.propertiesPayment.id ? _vm.$t("PROPERTIES_PAYMENTS.EDIT_PROPERTIES_PAYMENT") : _vm.$t("PROPERTIES_PAYMENTS.ADD_PROPERTIES_PAYMENT"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }