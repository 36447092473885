<template>
  <div class="container-fluid">
    <properties-payment-form
      :loading="loading"
      :propertiesPaymentData="propertiesPayment"
      :formErrors="formErrors"
      @propertiesPaymentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPropertiesPayment from "../defaultPropertiesPayment";
import PropertiesPaymentForm from "../partials/PropertiesPaymentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PropertiesPaymentForm,
  },

  mixins: [alertLeave],

  data() {
    const propertiesPayment = cloneDeep(defaultPropertiesPayment);
    const me = this.$store.getters["profile/me"];
    return {
      propertiesPayment: propertiesPayment,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(propertiesPayment) {
      this.loading = true;
      const propertiesPaymentData = cloneDeep(propertiesPayment);
      const propertiesPaymentPropertiesData = cloneDeep(
        propertiesPayment.propertiesPaymentsProperties
      );

      delete propertiesPaymentData.propertiesPaymentsProperties;

      try {
        await this.$store.dispatch(
          "propertiesPayments/add",
          propertiesPaymentData
        );
        const propertiesPayment =
          this.$store.getters["propertiesPayments/propertiesPayment"];

        for (const propertiesPaymentProperty of propertiesPaymentPropertiesData) {
          const propertiesPaymentPropertyData = {
            ...propertiesPaymentProperty,
            propertiesPayment: propertiesPayment,
            relationshipNames: ["property", "propertiesPayment"],
          };
          await this.$store.dispatch(
            "propertiesPaymentsProperties/add",
            propertiesPaymentPropertyData
          );
        }

        this.$notify({
          type: "success",
          message: this.$t("PROPERTIES_PAYMENTS.PROPERTIES_PAYMENT_ADDED"),
        });
        this.$emit("onViewPropertiesPayment", propertiesPayment, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
